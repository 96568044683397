'use strict'

import PouchDB from 'pouchdb';
PouchDB.plugin(require('pouchdb-find')); // npm install --save pouchdb-find

const service = process.env.VUE_APP_service_licenze || ''

const getConfigurazione = async (base, configurazioneId, subConf) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'configurazioni' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    const configurazione = await db.get(configurazioneId)
    await db.close()
    if (subConf) {
      return configurazione[subConf] || []
    } else {
      return configurazione
    }
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}

const setConfigurazione = async (base, configurazione) => {
  const url = process.env.VUE_APP_serviceUrl;
  const password = process.env.VUE_APP_servicePassword;
  const username = process.env.VUE_APP_serviceUsername;
  const dbName = 'configurazioni' // se necessario passare per utente
  const urlDB = `${url}/${base}_${dbName}`;
  try {
    var db = new PouchDB(urlDB, { auth: { username: username, password: password }})
    if (!db) return
    await db.put(configurazione)
    const newConf = await db.get(configurazione._id)
    await db.close()
    return newConf
  } catch (error) {
    // winston.error(`getDatiGenerici, errore: ${error}`)
    return error
  }
}

const getNumeratore = async (base, tipo, incrementa, annoRiferimento) => {
  let configurazioneId = 'documenti_numeratori';
  let configurazione = await getConfigurazione(base, configurazioneId);
  var numeratore
  var valoreNumeratore
  const d = new Date();
  annoRiferimento = parseInt(annoRiferimento) || d.getFullYear()
  try {
    numeratore = configurazione.numeratori[tipo]
    valoreNumeratore = configurazione.valoriNumeratori.find(x => x.tipo === numeratore.tipoValore && x.annoRiferimento === annoRiferimento)
  } catch(err) {
    console.log('errore:',err);
  }
  if (!numeratore) {
    return ''
  }
  if (!valoreNumeratore) {
    valoreNumeratore = {
      tipo: numeratore.tipoValore,
      numeroUltimo: 0,
      annoRiferimento: annoRiferimento
    }
    configurazione.valoriNumeratori.push(valoreNumeratore)
  }
  let num = numeratore.formato || ''; // es: xx_2020_123456_yy
  let numero = parseInt(valoreNumeratore.numeroUltimo);
  if (incrementa) {
    if (numeratore.autoIncrementaAnno && parseInt(valoreNumeratore.annoRiferimento) !== d.getFullYear()) {
      numero = 0;
      valoreNumeratore = {
        tipo: numeratore.tipoValore,
        annoRiferimento: d.getFullYear()
      }
      configurazione.valoriNumeratori.push(valoreNumeratore)
    }
    numero++;
    valoreNumeratore.numeroUltimo = numero;
    valoreNumeratore.dataUltimo = new Date()
    // await db.put(numeratore);
    // numeratore = await configurazioni.setNumeratore(url_conf, tipo, numeratore)
    // configurazione.numeratori[tipo] = numeratore
    const vv = configurazione.valoriNumeratori.find(x => x.tipo === numeratore.tipoValore && x.annoRiferimento === annoRiferimento)
    const idx = configurazione.valoriNumeratori.indexOf(vv)
    configurazione.valoriNumeratori[idx] = valoreNumeratore
    await setConfigurazione(base, configurazione)
  }
  let progressivo = String(numero).padStart(numeratore.numeroCifre, '0') 
  num = num.replace('#yyyy#', String(valoreNumeratore.annoRiferimento));
  num = num.replace('#yy#', String(valoreNumeratore.annoRiferimento).substring(2));
  num = num.replace('#progressivo#', String(progressivo));
  return num;
}

export default {
  getConfigurazione,
  setConfigurazione,
  getNumeratore
}
